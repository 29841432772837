:root {
  --bg-primary: #0a0a0a;
  --bg-secondary: #111;
  --bg-tertiary: #1a1a1a;
  --text-primary: #7fff00;
  --text-secondary: #00ffff;
  --text-tertiary: #ff00ff;
  --border-color: #3a3;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', monospace;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.App {
  height: 100vh;
}

.blotter-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.blotter-content {
  flex: 1;
  border: 2px solid var(--text-primary);
  box-shadow: 0 0 10px var(--text-primary), 0 0 20px var(--text-secondary), 0 0 30px var(--text-tertiary);
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.blotter-title {
  color: var(--text-primary);
  text-transform: uppercase;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px var(--text-tertiary);
}

.search-container {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: 10px;
  margin-bottom: 20px;
}

.search-prompt {
  color: var(--text-secondary);
  margin: 0;
  display: flex;
  align-items: center;
}

.search-input {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  margin-left: 5px;
  outline: none;
  flex-grow: 1;
}

.cursor {
  color: #ffffff;
}

.table-container {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}

.event-table {
  border-collapse: collapse;
  width: 100%;
}

.event-table th,
.event-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.event-table th {
  color: var(--text-secondary);
  border-bottom: 1px solid var(--text-primary);
}

.selected-row {
  background-color: var(--bg-tertiary);
  transform: translateZ(5px);
  box-shadow: 0 0 10px rgba(127, 255, 0, 0.5);
}

.event-type {
  white-space: nowrap;
}

.event-type.hack { color: #ff0000; }
.event-type.rug { color: #ff00ff; }
.event-type.enforcement { color: #ffff00; }

.event-type-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.amount {
  text-align: right;
}

.source-link {
  color: var(--text-secondary);
  text-decoration: none;
}

.blotter-footer {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-button {
  background-color: transparent;
  border: 1px solid;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.submit-button {
  border-color: var(--text-primary);
  color: var(--text-primary);
}

.contact-button {
  border-color: var(--text-secondary);
  color: var(--text-secondary);
}

@media (max-width: 768px) {
  .blotter-container {
    padding: 10px;
  }

  .blotter-content {
    padding: 10px;
  }

  .table-container {
    max-height: calc(100vh - 300px);
  }

  .event-table {
    font-size: 0.9rem;
  }
}